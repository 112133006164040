import { container, inject, singleton } from 'tsyringe';
import { DestinationsViewModel } from '../models/destinations-view-model';
import { DestinationViewModel } from '../models/destination-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';


@singleton()
export class DestinationApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }
    /*async getAllDestinationsAsync(pageIndex = 1, recordsPerPage = 100, sortOrder: number = -1) {
        return this.getObjectAsync<DestinationsViewModel>(`/api/destination?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortOrder=${sortOrder}`, this._authService.token);
    }*/
    async getAllDestinationsAsync(searchRequest: any) {
        return this.postObjectReturnObjectAsync<DestinationsViewModel>(`/api/destination/search`, searchRequest, this._authService.token);
    }
    async createAsync(destination: DestinationViewModel) {
        return this.postObjectReturnObjectAsync<{ id: number }>(`/api/destination`, destination, this._authService.token);
    }
    async getDestinationAsync(id: number) {
        return this.getObjectAsync<DestinationViewModel>(`/api/destination/${id}`, this._authService.token);
    }
    async testBucketAsync(destination: DestinationViewModel) {
        return this.postObjectAsync(`/api/destination/testBucket`, destination, this._authService.token);
    }
    async testGoogleDriveAsync(connection: {
        serviceAccount: string;
        destinationId: number;
        destinationName: string;
    }) {
        return this.postObjectAsync(`/api/destination/config/0/testGoogleDrive`, connection, this._authService.token);
    }
    async getGoogleDriveFoldersAsync(connection: {
        serviceAccount: string;
        destinationId: number;
        destinationName: string;
    }) {
        return this.postObjectReturnObjectAsync<string[]>(`/api/destination/config/0/getGoogleDriveFolders`, connection, this._authService.token);
    }
    //async GetSpreadsheets(Id: string,type: string) {
    //    return this.getObjectAsync(`/api/destination/GetSpreadsheets?Id=${Id}&type=${type}`, this._authService.token);
    //}
    async callbackgoogle(code: string) {
        return this.postObjectAsync(`/api/destination/Callbackgoogle`, code, this._authService.token);
    }
    async getGoogleOauthLink(type: string = "drive") {
        return this.getObjectAsync<string>(`/api/destination/googleOauthLink/${type}`, this._authService.token);
    }
    async getAWSExportUserAsync() {
        return this.getObjectAsync<string>(`/api/destination/aws-export-user`, this._authService.token);
    }
    async updateAsync(destinationId: number, destination: DestinationViewModel) {
        return this.putObjectAsync(`/api/destination/${destinationId}`, destination, this._authService.token);
    }
    async deleteAllAsync(destinationIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/destination/all`, { ids: destinationIds }, this._authService.token);
    }
    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/destination/${id}`, this._authService.token);
    }
}