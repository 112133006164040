import { Router } from "@vaadin/router";
import { singleton } from "tsyringe";

@singleton()
export class RouterService {
    private _router: Router;

    initialize(outlet: HTMLDivElement) {
        this._router = new Router(outlet);
        this._router.setRoutes([
            {
                path: "",
                component: "se-home",
                children: [
                    {
                        path: "/",
                        redirect: "/space",
                    },
                    {
                        path: "/dashboard",
                        component: "se-dashboard",
                    },
                    {
                        path: "/space/:spaceId?",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/template/:templateId",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/shared-file/:sharedFileId",
                        component: "se-space",
                    },
                    {
                        path: "/edit/space/:spaceId?",
                        component: "se-space-editor",
                    },
                    {
                        path: "/edit/label/:labelId?/space/:spaceId?",
                        component: "se-label-editor",
                    },
                    {
                        path: "/manage/labels/space/:spaceId?",
                        component: "se-labels",
                    },
                    {
                        path: "/manage/spaces",
                        component: "se-spaces",
                    },
                    {
                        path: "/manage/org-servers",
                        component: "se-organization-servers",
                    },
                    {
                        path: "/manage/servers",
                        component: "se-servers",
                    },
                    {
                        path: "/edit/editor-server/:serverId?",
                        component: "se-editor-server-editor",
                    },
                    {
                        path: "/edit/server/:serverId?",
                        component: "se-server-editor",
                    },
                    {
                        path: "/manage/server-groups",
                        component: "se-server-groups",
                    },
                    {
                        path: "/edit/server-group/:serverGroupId?",
                        component: "se-server-group-editor",
                    },
                    {
                        path: "/manage/user-groups",
                        component: "se-user-groups",
                    },
                    {
                        path: "/edit/user-group/:userGroupId?",
                        component: "se-user-group-editor",
                    },
                    {
                        path: "/manage/org-users",
                        component: "se-organization-users",
                    },
                    {
                        path: "/edit/user/:userId?",
                        component: "se-user-editor",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/config/:configId/run/:taskId?",
                        component: "se-start-run",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/task/:taskId?",
                        component: "se-start-run",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/many-runs",
                        component: "se-start-many-runs",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/template/:templateId/many-runs",
                        component: "se-start-many-runs",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/runs",
                        component: "se-runs",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/tasks",
                        component: "se-agent-tasks",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/agent-pricing",
                        component: "se-agent-pricing-editor",
                    },
                    {
                        path: "/space/:spaceId/shared-file/:configId/details",
                        component: "se-shared-file-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/details/:gridType",
                        component: "se-agent-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/details/:gridType/run/:runId",
                        component: "se-agent-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/run/:runId",
                        component: "se-run-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/run-history",
                        component: "se-run-history",
                    },
                    {
                        path: "/proxies",
                        component: "se-proxies",
                    },
                    {
                        path: "/edit/proxy-pool/:proxyPoolId?",
                        component: "se-proxy-editor",
                    },
                    {
                        path: "/destinations",
                        component: "se-destinations",
                    },
                    {
                        path: "/edit/destination/:destinationId?",
                        component: "se-destination-editor",
                    },
                    {
                        path: "/destinations/oauth-response",
                        component: "se-oauth-response",
                    },
                    {
                        path: "/manage/server-keys",
                        component: "se-server-keys",
                    },
                    {
                        path: "/edit/server-key/:serverKeyId?",
                        component: "se-server-key-editor",
                    },
                    {
                        path: "/edit/shared-file/new",
                        component: "se-shared-file-create",
                    },
                    {
                        path: "/edit/shared-file/:configId?/editor",
                        component: "se-shared-file-editor",
                    },
                    {
                        path: "/manage/organizations/:approvalString?",
                        component: "se-organizations",
                    },
                    {
                        path: "/edit/organization/:organizationId?",
                        component: "se-organization-editor",
                    },
                    {
                        path: "/manage/api-keys/:userId",
                        component: "se-api-keys",
                    },
                    {
                        path: "/edit/api-key/:userId/:apiKeyId?",
                        component: "se-api-key-editor",
                    },
                    {
                        path: "/manage/rate-limits",
                        component: "se-rate-limits",
                    },
                    {
                        path: "/manage/org",
                        component: "se-organization-settings",
                    },
                    {
                        path: "/manage/org/:tab?",
                        component: "se-organization-settings",
                    },
                    {
                        path: "/manage/admin",
                        component: "se-admin-settings",
                    },
                    {
                        path: "/manage/admin/:tab?",
                        component: "se-admin-settings",
                    },
                    {
                        path: "/manage/app-settings",
                        component: "se-app-settings",
                    },
                    {
                        path: "/edit/rate-limit/:rateLimitId?",
                        component: "se-rate-limit-editor",
                    },
                    {
                        path: "/audit-log/:gridType?",
                        component: "se-audit-log",
                    },
                    {
                        path: "/all-runs",
                        component: "se-all-runs",
                    },
                    {
                        path: "/org-runs",
                        component: "se-org-runs",
                    },
                    {
                        path: "/billing/:tab?",
                        component: "se-billing",
                    },
                    {
                        path: "/usage",
                        component: "se-usage",
                    },
                    {
                        path: "/usage/:checkoutStatus?",
                        component: "se-billing",
                    },
                    {
                        path: "/agent-usage/:pipeId?",
                        component: "se-pipe-usage",
                    },
                ],
            },
            {
                path: "/login",
                component: "se-login",
            },
            {
                path: "/forgot-password",
                component: "se-forgot-password",
            },
            {
                path: "/reset-password",
                component: "se-reset-password",
            },
            {
                path: "/change-password",
                component: "se-change-password",
            },

            {
                path: "/create-first-user",
                component: "se-create-first-user",
            },
            {
                path: "/register",
                component: "se-user-registration",
            },            
            {
                path: "/multi-factor-authentication-setup",
                component: "se-multi-factor-authentication-setup",
            },
            {
                path: "/multi-factor-authentication",
                component: "se-multi-factor-authentication",
            },
        ]);
    }
}
