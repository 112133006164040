import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";

@customElement("se-organization-settings")
export class SeOrganizationUsersElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _selectedTab: string = "users";

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }


    private tabSwitch(tab: string) {
        this._selectedTab = tab;
        history.pushState(null, null, '/manage/org/' + this._selectedTab);
    }

    render() {
        return html`
            <div class="body">
                <div class="header">
                    <h1>Manage ${this._authService.user.organizationName}</h1>
                </div>
                <div class="tabs-header">
                    <div @click=${() => this.tabSwitch("users")} class="tabs ${classMap({ "active-tab": this._selectedTab == "users" })}">Users</div>
                    <div @click=${() => this.tabSwitch("proxies")} class="tabs ${classMap({ "active-tab": this._selectedTab == "proxies" })}">Proxy Pools</div>
                    <div @click=${() => this.tabSwitch("destinations")} class="tabs ${classMap({ "active-tab": this._selectedTab == "destinations" })}">Destinations</div>
                    <div @click=${() => this.tabSwitch("transactions")} class="tabs ${classMap({ "active-tab": this._selectedTab == "transactions" })}">Transactions</div>
                    <div @click=${() => this.tabSwitch("audit")} class="tabs ${classMap({ "active-tab": this._selectedTab == "audit" })}">Audit Logs</div>
                    <div @click=${() => this.tabSwitch("agents")} class="tabs ${classMap({ "active-tab": this._selectedTab == "agents" })}">Audit Agents</div>
                    <div @click=${() => this.tabSwitch("runs")} class="tabs ${classMap({ "active-tab": this._selectedTab == "runs" })}">Audit Runs</div>
                    <div @click=${() => this.tabSwitch("logins")} class="tabs ${classMap({ "active-tab": this._selectedTab == "logins" })}">Audit Logins</div>

                </div>
                <div class="container-content ${classMap({ "config-tab": this._selectedTab != "users" })}">
                    ${(this._selectedTab == "users") ?
                        html`<se-organization-users></se-organization-users>` : ``
                    }
                    ${(this._selectedTab == "proxies") ?
                        html`<se-proxies></se-proxies>` : ``
                    }
                    ${(this._selectedTab == "destinations") ?
                        html`<se-destinations></se-destinations>` : ``
                    }
                    ${(this._selectedTab == "transactions") ?
                        html`<se-transactions></se-transactions>` : ``
                    }
                    ${(this._selectedTab == "audit") ?
                        html`<se-account-audit-log></se-account-audit-log>` : ``
                    }
                    ${(this._selectedTab == "agents") ?
                        html`<se-config-audit-log></se-config-audit-log>` : ``
                    }
                    ${(this._selectedTab == "runs") ?
                        html`<se-run-audit-log></se-run-audit-log>` : ``
                    }
                    ${(this._selectedTab == "logins") ?
                        html`<se-login-audit-log></se-login-audit-log>` : ``
                    }
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
            margin-bottom: 10px;
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
        }
    `;
}
