import { Router } from "@vaadin/router";
import { LitElement } from "lit";
import { container, inject, singleton } from "tsyringe";
import { err, NetworkError, ok, Result } from "se-shared/utils/result";
import { SeEvent } from "../../utils/se-events";
import { ConfigScope } from "../enums/config-scope";
import { LabelFilter } from "../enums/label-filter";
import { UserLabel } from "../models/user-label";
import { UserLabels } from "../models/user-labels";
import { UserSpace } from "../models/user-space";
import { UserSpaces } from "../models/user-spaces";
import { UserApi } from "./user-api";
import { ConfigType } from "../enums/config-type";
import { ValidationStatus } from "../enums/validation-status";

@singleton()
export class UserState {
    private _userApi: UserApi;

    private _userSpacesChanged = new SeEvent();
    private _userLabelsChanged = new SeEvent();
    private _userSpaces: UserSpace[];
    private _userLabels: UserLabel[];

    private _draftCountChanged = new SeEvent<number>();
    private _totalSpaceCountChanged = new SeEvent<number>();
    private _totalLabelCountChanged = new SeEvent<number>();
    private _gridRowsChanged = new SeEvent();
    private _gridDataChanged = new SeEvent();
    private _selectedSpaceOrLabelChanged = new SeEvent();

    private _selectedLabelId = 0;
    private _selectedSpaceId = -2;
    private _draftCount = 0;
    private _totalSpaceCount = 0;
    private _totalLabelCount = 0;

    selectedConfigId?: number;
    selectedConfigName?: string;
    selectedConfigType?: ConfigType;
    selectedConfigValidationStatus?: ValidationStatus;

    selectedIds: number[]; //General variable to pass selected elements between routes.

    get draftCount() {
        return this._draftCount;
    }
    get totalSpaceCount() {
        return this._totalSpaceCount;
    }
    get totalLabelCount() {
        return this._totalLabelCount;
    }

    set selectedSpaceId(val: number) {
        if (this._selectedSpaceId !== val) {
            this._selectedSpaceId = val;
            if (this._selectedSpaceId >= 0 || this._selectedSpaceId == -2) localStorage.setItem("spaceId", this._selectedSpaceId.toString());
            if (this.selectedLabelId !== -1) this.selectedLabelId = 0;
            this._userLabels = undefined;
        }
    }
    get selectedSpaceId() {
        return this._selectedSpaceId;
    }
    get isDraftSpace() {
        return this._selectedSpaceId === -1;
    }
    get isDashboardSpace() {
        return this._selectedSpaceId === -2;
    }

    get selectedLabelId() {
        return this._selectedLabelId;
    }
    set selectedLabelId(val: number) {
        if (this._selectedLabelId !== val) {
            this._selectedLabelId = val;
            if (this._selectedLabelId >= 0) localStorage.setItem("labelId", this._selectedLabelId.toString());
        }
    }

    router: Router;

    get draftCountChanged() {
        return this._draftCountChanged;
    }
    get totalSpaceCountChanged() {
        return this._totalSpaceCountChanged;
    }
    get totalLabelCountChanged() {
        return this._totalLabelCountChanged;
    }
    get gridRowsChanged() {
        return this._gridRowsChanged;
    }
    get gridDataChanged() {
        return this._gridDataChanged;
    }
    get selectedSpaceOrLabelChanged() {
        return this._selectedSpaceOrLabelChanged;
    }

    get userSpacesChanged() {
        return this._userSpacesChanged;
    }
    get userLabelsChanged() {
        return this._userLabelsChanged;
    }

    constructor() {
        this._userApi = container.resolve(UserApi);
    }

    updateDraftCount(count: number) {
        if (this._draftCount !== count) {
            this._draftCount = count;
            this._draftCountChanged.trigger(count);
        }
    }

    updateTotalSpaceCount(count: number) {
        if (this._totalSpaceCount !== count) {
            this._totalSpaceCount = count;
            this._totalSpaceCountChanged.trigger(count);
        }
    }

    updateTotalLabelCount(count: number) {
        if (this._totalLabelCount !== count) {
            this._totalLabelCount = count;
            this._totalLabelCountChanged.trigger(count);
        }
    }

    async getUserSpacesAsync(pageIndex = 1, recordsPerPage = 100): Promise<Result<UserSpace[], NetworkError>> {
        if (!this._userSpaces) {
            const spaces = await this.refreshUserSpacesAsync(pageIndex, recordsPerPage);
            if (spaces.err) {
                return err(spaces.err);
            }
        }
        return ok(this._userSpaces);
    }

    async refreshUserSpacesAsync(
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1
    ): Promise<Result<UserSpaces, NetworkError>> {
        const spaces = await this._userApi.getSpacesAsync(pageIndex, recordsPerPage, sortColumn, sortOrder);
        if (spaces.isOk) {
            this._userSpaces = spaces.value.userSpaces;
            if (!this.isDashboardSpace && !this.isDraftSpace && !this._userSpaces.some((p) => p.id === this._selectedSpaceId)) {
                this.selectedSpaceId = 0;
                this._userLabels = undefined;
            }
            this.updateDraftCount(spaces.value.draftCount);
            this.updateTotalSpaceCount(spaces.value.count);
            this._userSpacesChanged.triggerVoid();
        }
        return spaces;
    }

    async getUserLabelsAsync(pageIndex = 1, recordsPerPage = 100): Promise<Result<UserLabel[], NetworkError>> {
        if (!this._userLabels) {
            const labels = await this.refreshUserLabelsAsync(pageIndex, recordsPerPage);
            if (labels.err) {
                return err(labels.err);
            }
        }
        return ok(this._userLabels);
    }

    async refreshUserLabelsAsync(
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1,
        isNotify = true
    ): Promise<Result<UserLabels, NetworkError>> {
        if (!this.isDraftSpace) {
            const labels = this._selectedSpaceId
                ? await this._userApi.getLabelsAsync(this._selectedSpaceId, pageIndex, recordsPerPage, sortColumn, sortOrder)
                : await this._userApi.getHomeLabelsAsync(pageIndex, recordsPerPage, sortColumn, sortOrder);
            if (labels.isOk) {
                this._userLabels = labels.value.userLabels;
                this.updateTotalLabelCount(labels.value.count);
                if (isNotify) this._userLabelsChanged.triggerVoid();
            }
            return labels;
        } else {
            const userLabels = new UserLabels();
            this._userLabels = [];
            userLabels.userLabels = this._userLabels;
            return ok(userLabels);
        }
    }

    async removeUserLabelsAsync(labelIds: number[]): Promise<Result<UserLabel[], NetworkError>> {
        if (!this._userLabels) {
            const labels = await this.refreshUserLabelsAsync();
            if (labels.err) {
                return err(labels.err);
            }
        } else {
            this._userLabels = this._userLabels.filter((p) => !labelIds.includes(p.id));
        }
        return ok(this._userLabels);
    }

    async getSelectedLabel(): Promise<Result<UserLabel, NetworkError>> {
        if (!this.selectedLabelId) {
            return ok({ id: 0, name: "All Configs", isBookmark: true, labelType: LabelFilter.Config, isAdminLabel: true });
        } else {
            const labels = await this.getUserLabelsAsync();
            if (labels.isErr) {
                return err(labels.err);
            } else {
                const label = this._userLabels.find((p) => p.id == this.selectedLabelId);
                if (label) return ok(label);
                else return err(new NetworkError(`Unexpected error. Could not find label with ID: ${this.selectedLabelId}`));
            }
        }
    }
    async getSelectedSpace(pageIndex = 1): Promise<Result<UserSpace, NetworkError>> {
        if (!this.selectedSpaceId) {
            return ok({ id: 0, name: "Personal", scope: ConfigScope.Private, isBookmark: true });
        } else {
            const spaces = await this.getUserSpacesAsync(pageIndex);
            if (spaces.isErr) {
                return err(spaces.err);
            } else {
                const space = this._userSpaces.find((p) => p.id == this.selectedSpaceId);
                if (space) return ok(space);
                else return err(new NetworkError(`Unexpected error. Could not find space with ID: ${this.selectedSpaceId}`));
            }
        }
    }

    getStoredSpaceId() {
        const spaceId = localStorage.getItem("spaceId");
        return spaceId ? parseInt(spaceId) : 0;
    }
    getStoredLabelId() {
        const labelId = localStorage.getItem("labelId");
        return labelId && labelId !== "-1" ? parseInt(labelId) : 0;
    }
}
