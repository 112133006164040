import { container, singleton } from "tsyringe";
import { RunStatus } from "../enums/run-status";
import { AgentTasksViewModel } from "../models/agent-tasks-view-model";
import { CreateManyRunsModel, CreateRunModel } from "../models/create-run-model";
import { RunFileViewModel } from "../models/run-file-model";
import { RunDetailsViewModel, RunViewModel } from "../models/run-view-model";
import { ScheduleRunModel, ScheduleRunWithProxyViewModel } from "../models/schedule-run-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class RunApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getManyAsync(configId: number) {
        return this.getObjectAsync<{ configName: string; runs: RunViewModel[] }>(`/api/run/config/${configId}`, this._authService.token);
    }
    async getAllAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "startTime", sortOrder = -1) {
        return this.getObjectAsync<{ runsHistory: RunViewModel[]; totalRecordCount: number }>(
            `/api/run/all?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }
    async getOrgRunsAsync(searchObj) {
        return this.postObjectReturnObjectAsync<{ runsHistory: RunViewModel[]; totalRecordCount: number }>(
            `/api/run/org-runs`, searchObj, this._authService.token
        );
    }
    async getManySinceEpochAsync(configId: number, epoch: number) {
        return this.getObjectAsync<{ configName: string; runs: RunViewModel[] }>(
            `/api/run/config/${configId}/epoch/${epoch}`,
            this._authService.token
        );
    }
    async getManyTasksAsync(configId: number) {
        return this.getObjectAsync<{ configName: string; agentTasks: AgentTasksViewModel[] }>(
            `/api/run/config/${configId}/tasks`,
            this._authService.token
        );
    }
    async getAsync(runId: number) {
        return this.getObjectAsync<{ configName: string; run: RunDetailsViewModel; files: RunFileViewModel[] }>(
            `/api/run/${runId}`,
            this._authService.token
        );
    }
    async getTaskAsync(taskId: number) {
        return this.getObjectAsync<ScheduleRunWithProxyViewModel>(`/api/run/task/${taskId}`, this._authService.token);
    }
    async createAsync(run: CreateRunModel) {
        return this.postObjectAsync(`/api/run`, run, this._authService.token);
    }
    async createManyAsync(run: CreateManyRunsModel) {
        return this.postObjectAsync(`/api/run/many`, run, this._authService.token);
    }
    async stopManyConfigsAsync(configIds: number[]) {
        return this.postObjectAsync(`/api/run/stop-many-configs`, { ids: configIds }, this._authService.token);
    }
    async scheduleAsync(task: ScheduleRunModel) {
        return this.postObjectAsync(`/api/run/schedule`, task, this._authService.token);
    }
    async updateTaskAsync(taskId: number, task: ScheduleRunModel) {
        return this.putObjectAsync(`/api/run/schedule/${taskId}`, task, this._authService.token);
    }
    async stopMany(configId: number, runIds: number[]) {
        return this.postObjectAsync(`/api/run/config/${configId}/stop/many`, { ids: runIds }, this._authService.token);
    }
    async kill(runId: number) {
        return this.postAsync(`/api/run/${runId}/kill`, this._authService.token);
    }
    async restart(runId: number) {
        return this.postAsync(`/api/run/${runId}/restart`, this._authService.token);
    }
    async continue(runId: number) {
        return this.postAsync(`/api/run/${runId}/continue`, this._authService.token);
    }
    async retry(runId: number) {
        return this.postAsync(`/api/run/${runId}/retry`, this._authService.token);
    }
    async checkStatus(runId: number) {
        return this.getObjectAsync<RunViewModel>(`/api/run/${runId}/status/check`, this._authService.token);
    }
    async failRun(runId: number) {
        return this.postAsync(`/api/run/${runId}/fail`, this._authService.token);
    }
    async failMany(configId: number, runIds: number[]) {
        return this.postObjectAsync(`/api/run/config/${configId}/fail-many`, { ids: runIds }, this._authService.token);
    }

    /*async setStatusOfMany(configId: number, runIds: number[], status: RunStatus) {
        return this.postObjectAsync(`/api/run/config/${configId}/status/${status}/many`, { ids: runIds }, this._authService.token);
    }*/

    async deleteManyBySequenceAsync(configId: number, sequences: number[]) {
        return this.deleteAllObjectsAsync(`/api/run/config/${configId}/many`, { ids: sequences }, this._authService.token);
    }

    async deleteBySequenceAsync(configId: number, sequence: number) {
        return this.deleteObjectAsync(`/api/run/config/${configId}/sequence/${sequence}`, this._authService.token);
    }

    async deleteTaskManyAsync(configId: number, runIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/run/schedule/config/${configId}/many`, { ids: runIds }, this._authService.token);
    }

    async deleteTaskAsync(configId: number, taskId: number) {
        return this.deleteObjectAsync(`/api/run/schedule/${taskId}/config/${configId}`, this._authService.token);
    }

    async downloadFile(runId: number, fileId: number, fileReturnType: string) {
        const response = await this.postObjectReturnObjectAsync<{ downloadUrl: string }>(
            `/api/run/${runId}/file/${fileId}/download/authorize`,
            null,
            this._authService.token
        );

        if (response.isOk) {
            const url = response.value.downloadUrl;
            if (url !== null) {
                //const url = `/api/run/${runId}/file/${fileId}/download?token=${fileToken}`;
                //window.location.href =
                window.open(url + `&fileReturnType=${fileReturnType}`, "_blank");
            }
        }
    }
}
