import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import "../billing/transactions.element";

@customElement("se-admin-settings")
export class SeOrganizationUsersElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _selectedTab: string = "servers";

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }


    private tabSwitch(tab: string) {
        this._selectedTab = tab;
        history.pushState(null, null, '/manage/admin/' + this._selectedTab);
    }

    
    render() {
        return html`
            <div class="body">
                <div class="header">
                    <h1>Admin Settings</h1>
                </div>
                <div class="tabs-header">
                    <div @click=${() => this.tabSwitch("servers")} class="tabs ${classMap({ "active-tab": this._selectedTab == "servers" })}">Servers</div>
                    <div @click=${() => this.tabSwitch("keys")} class="tabs ${classMap({ "active-tab": this._selectedTab == "keys" })}">Server Keys</div>
                    <div @click=${() => this.tabSwitch("orgs") } class="tabs ${classMap({ "active-tab": this._selectedTab == "orgs" })}">Organizations</div>
                    <div @click=${() => this.tabSwitch("rates")} class="tabs ${classMap({ "active-tab": this._selectedTab == "rates" })}">Rate Limits</div>
                    <div @click=${() => this.tabSwitch("runs")} class="tabs ${classMap({ "active-tab": this._selectedTab == "runs" })}">All Runs</div>
                    <div @click=${() => this.tabSwitch("settings")} class="tabs ${classMap({ "active-tab": this._selectedTab == "settings" })}">App Settings</div>
                    <div @click=${() => this.tabSwitch("audit")} class="tabs ${classMap({ "active-tab": this._selectedTab == "audit" })}">System Audit</div>
                </div>
                <div class="container-content">
                    ${(this._selectedTab == "servers") ?
                        html`<se-servers></se-servers>` : ``
                    }
                    ${(this._selectedTab == "keys") ?
                        html`<se-server-keys></se-server-keys>` : ``
                    }
                    ${(this._selectedTab == "orgs") ?
                        html`<se-organizations></se-organizations>` : ``
                    }
                    ${(this._selectedTab == "rates") ?
                        html`<se-rate-limits></se-rate-limits>` : ``
                    }
                    ${(this._selectedTab == "runs") ?
                        html`<se-all-runs></se-all-runs>` : ``
                    }
                    ${(this._selectedTab == "settings") ?
                        html`<se-app-settings></se-app-settings>` : ``
                    }
                    ${(this._selectedTab == "audit") ?
                        html`<se-login-audit-log></se-login-audit-log>` : ``
                    }
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
        }
    `;
}
