import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { DestinationType } from "../../enums/destination-types";
import { FtpProtocol } from "../../enums/ftp-protocol";
import { DestinationViewModel } from "../../models/destination-view-model";
import { AuthService } from "../../services/auth.service";
import { DestinationService } from "../../services/destination.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import "../components/google-oauth-button.element";
import { SePrimaryButton } from "../components/primary-button.element";
import { BaseEditor } from "../editors/base-editor";
import { SelectEditorInlineElement } from "se-shared/elements/editors/inline/select-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";
import { GoogleOAuthType } from "../../enums/google-oauth-type";
import { isValidJson } from "se-shared/utils/utils";
import { TypeaheadEditorElement } from "../editors/typeahead-editor.element";
import { nanoid } from "nanoid";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { SnowflakeAuthType } from "../../enums/snowflake-auth-type";

@customElement("se-destination-editor")
export class SeDestinationEditorElement extends LitElement {
    private _modalService: ModalDialogService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _destinationService: DestinationService;
    private _isNew = false;
    private _Sheets;
    private _destinationId?: number;
    private _destinationsList: string;
    private _AWSExportUser;
    private gServiceAccountValue: string;
    private snowflakePrivateKeyValue: string;
    private _externalId: string;
    @state() private _destination: DestinationViewModel;
    private _SheetTempId;

    @query("#saveBtn") private _saveButton: SePrimaryButton;
    @query("#destinationName") private _nameEditor: InputEditorElement;
    @query("#bucketName") private _bucketNameEditor: InputEditorElement;
    @query("#bucketFolder") private _bucketFolderEditor: InputEditorElement;
    @query("#externalId") private _externalIdEditor: InputEditorElement;
    @query("#roleArn") private _roleArnEditor: InputEditorElement;
    @query("#requireExternalId") private _requireExternalIdEditor: CheckboxEditorElement;

    @query("#testButton") private _testButton: SePrimaryButton;
    @query("#description") private _descriptionEditor: TextAreaEditorElement;
    @query("#destinationType") private _destinationType: InputEditorElement;
    @query("#type") private _typeEditor: InputEditorElement;
    @query("#policy") private _policyEditor: TextAreaEditorElement;
    @query("#customTrustPolicy") private _customTrustPolicyEditor: TextAreaEditorElement;
    @query("#gCredentials") private _gCredentialsEditor: TextAreaEditorElement;
    @query("#googleSheetsList") private _sheetsEditor: InputEditorElement;
    @query("#gDriveFolder") private _gDriveFolderEditor: TypeaheadEditorElement;

    //Snowflake fields
    @query("#snowflakeAccount") private _snowflakeAccountEditor: InputEditorElement;
    @query("#snowflakeUsername") private _snowflakeUserNameEditor: InputEditorElement;
    @query("#snowflakePassword") private _snowflakePasswordEditor: InputEditorElement;
    @query("#snowflakeWarehouse") private _snowflakeWarehouseEditor: InputEditorElement;
    @query("#snowflakeDatabase") private _snowflakeDatabaseEditor: InputEditorElement;
    @query("#snowflakeSchema") private _snowflakeSchemaEditor: InputEditorElement;
    @query("#snowflakeRole") private _snowflakeRoleEditor: InputEditorElement;
    @query("#snowflakeStage") private _snowflakeStageEditor: InputEditorElement;
    @query("#snowflakePrivateKey") private _snowflakePrivateKeyEditor: TextAreaEditorElement;

    //FTP fields
    @query("#ftpProtocol") private _ftpProtocolEditor: SelectEditorInlineElement;
    @query("#ftpHost") private _ftpHostEditor: InputEditorElement;
    @query("#ftpPort") private _ftpPortEditor: InputEditorElement;
    @query("#ftpUsername") private _ftpUsernameEditor: InputEditorElement;
    @query("#ftpPassword") private _ftpPasswordEditor: InputEditorElement;
    @query("#ftpPrivateKey") private _ftpPrivateKeyEditor: TextAreaEditorElement;
    @state() private _ftpProtocol = FtpProtocol.Ftp;
    @state() private _ftpPort = 21;
    @state() private _ftpPrivateKey: string;
    @state() private _ftpPassword: string;

    @state() private _googleOAuthType = GoogleOAuthType.SignInButton;
    @query("#googleOAuthType") private _googleOAuthTypeEditor: SelectEditorInlineElement;

    @state() private _snowflakeAuthType = SnowflakeAuthType.Basic;
    @query("#snowflakeAuthType") private _snowflakeAuthTypeEditor: SelectEditorInlineElement;

    @state() private _isEdit = false;
    @state() private _showBucketConfig = true;
    @state() private _showGoogleSheetConfig = false;
    @state() private _showGoogleDriveConfig = false;
    @state() private _showGAuthButton = false;
    @state() private _showGSheetField = false;
    @state() private _showGDriveField = false;
    @state() private _showSnowflakeConfig = false;
    @state() private _showFtpConfig = false;
    @state() private _requireExternalId = true;
    @state() private _policyRows = 15;
    @state() private _isLoading = true;

    @state() private _googleDriveFolders: string[] = [];

    private _protocolOptions = Object.entries(FtpProtocol).map(([, value]) => ({ id: value, name: value }));
    private _googleOAuthTypeOptions = Object.entries(GoogleOAuthType).map(([, value]) => ({ id: value, name: value }));
    private _snowflakeAuthTypeOptions = Object.entries(SnowflakeAuthType).map(([, value]) => ({ id: value, name: value }));

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._destinationService = container.resolve(DestinationService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this.handleMessage = this.handleMessage.bind(this);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        //check if we are editing or creating
        if (location.params.destinationId) {
            this._destinationId = parseInt(location.params.destinationId.valueOf() as string);
            this._SheetTempId = this._destinationId;
            this._isNew = false;
        } else {
            this._isNew = true;
            this._isLoading = false;
            this._destination = new DestinationViewModel();
            this.generateExternalId();
        }

        //getuser aws account used for export
        this._AWSExportUser = await this._destinationService.api.getAWSExportUserAsync();
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("message", this.handleMessage);

        if (!this._isNew) this.loadDestinationAsync();
    }

    disconnectedCallback() {
        window.removeEventListener("message", this.handleMessage);
        super.disconnectedCallback();
    }

    private async loadDestinationAsync() {
        try {
            const res = await this._destinationService.api.getDestinationAsync(this._destinationId);
            if (res.isOk) {
                this._destination = res.value;
                this._destination.parsedConfigJson = JSON.parse(this._destination.configJson);
                this._isEdit = true;                
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } finally {
            this._isLoading = false;
        }
    }
    updated(changedProperties) {
        //change to preselected type when editing a destination
        if (changedProperties.has("_destination")) {
            this.setDestinationType(this._destination?.destinationType);
        }
        this.changePolicy()
    }

    firstUpdated() {
        if (this._destination) {
            this.setDestinationType(this._destination.destinationType);
        }
    }

    private setDestinationType(type) {
        if (this._destinationType) {
            this._destinationType.value = type;
            this.typeChangeEdit(type);
        }
    }
    private handleMessage(event) {
        const res = event.data;
        //receive the temporary created destination ID from the google oauth callback
        if (res.message === "oauthResponse") {
            this._SheetTempId = res.tempId;
            this.typeChangeEdit(true);
        }
    }

    nameChanged() {
        this.changePolicy();
    }
    externalIdChange() {
        this._externalId = this._externalIdEditor.liveValue
        if (this._destination.parsedConfigJson) {
            this._destination.parsedConfigJson.externalId = this._externalIdEditor.liveValue
        }
        this.changePolicy();
    }
    requireExternalIdChanged() {
        this._requireExternalId = this._requireExternalIdEditor.liveValue === true;

        if (this._requireExternalId) this._policyRows = 12;
        else this._policyRows = 15;

        this.changePolicy();
    }

    async changePolicy() {
        //generate a policy everytime the bucket name or require External Id changes

        await this.updateComplete;

        if (this._bucketNameEditor?.liveValue || this._externalIdEditor?.liveValue) {
            if (this._bucketNameEditor.liveValue) {
                if (this._requireExternalIdEditor.liveValue === true) {
                    this._policyEditor.value = `{
                  "Version": "2012-10-17",
                  "Statement": [
                    {
                      "Action": [
                        "s3:PutObject", "s3:PutObjectAcl"
                      ],
                      "Effect": "Allow",
                      "Resource": "arn:aws:s3:::${this._bucketNameEditor.liveValue}/*"
                    }
                  ]
                }`;
                } else {
                    this._policyEditor.value = `{
                  "Version": "2012-10-17",
                  "Statement": [
                    {
                      "Action": [
                        "s3:PutObject", "s3:PutObjectAcl"
                      ],
                      "Effect": "Allow",
                      "Resource": "arn:aws:s3:::${this._bucketNameEditor.liveValue}/*",
                      "Principal": {
                        "AWS":"${this._AWSExportUser.value}"
                      }
                    }
                  ]
                }`;
                }
            }
        } else {
            return 
        }
    }

    getCustomTrustPolicy() {
        return `{
                  "Version": "2012-10-17",
                  "Statement": [
                    {
                      "Effect": "Allow",
                      "Principal": {
                        "AWS":"${this._AWSExportUser.value}"
                      },
                      "Action": "sts:AssumeRole",
                      "Condition": {
                        "StringEquals": {
                            "sts:ExternalId": "${this._externalId ? this._externalId : this._destination?.parsedConfigJson?.externalId}"
                        }
                       }
                    }
                  ]
                }`;
    }
    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._toasterService.clear();

            let destination;

            //each destination has a different process
            if (this._destinationType.liveValue === DestinationType.S3) {
                //  this.validateS3Bucket();

                this._saveButton.disabled = true;

                const s3ConfigJson = this.prepareS3ConfigJson();
                destination = this.prepareS3Destination(s3ConfigJson);
            } else if (this._destinationType.liveValue === DestinationType.GoogleSheets) {
                this._googleOAuthType = this._googleOAuthTypeEditor.liveValue as GoogleOAuthType;
                if (
                    this._googleOAuthType === GoogleOAuthType.ServiceAccount &&
                    (!this._gCredentialsEditor.liveValue.trim() ||
                        !(this._gCredentialsEditor.liveValue.trim() === "[hidden]" || isValidJson(this._gCredentialsEditor.liveValue)))
                ) {
                    return this._toasterService.showError("Please enter service account credentials.");
                }

                destination = this.buildGoogleSheetDestination();
            } else if (this._destinationType.liveValue === DestinationType.GoogleDrive) {
                this._googleOAuthType = this._googleOAuthTypeEditor.liveValue as GoogleOAuthType;
                if (
                    this._googleOAuthType === GoogleOAuthType.ServiceAccount &&
                    (!this._gCredentialsEditor.liveValue.trim() ||
                        !(this._gCredentialsEditor.liveValue.trim() === "[hidden]" || isValidJson(this._gCredentialsEditor.liveValue)))
                ) {
                    return this._toasterService.showError("Please enter service account credentials.");
                }

                destination = this.buildGoogleDriveDestination();
            } else if (this._destinationType.liveValue === DestinationType.Snowflake) {
                this._snowflakeAuthType = this._snowflakeAuthTypeEditor.liveValue as SnowflakeAuthType;
                if (this._snowflakeAuthType === SnowflakeAuthType.PrivateKey && !this._snowflakePrivateKeyEditor.liveValue.trim()) {
                    return this._toasterService.showError("Please enter a private key.");
                }

                if (/\s/.test(this._snowflakeStageEditor.liveValue)) {
                    return this._toasterService.showError("Default named stage should not contain spaces.");
                }
                const snowflakeConfigJson = {
                    account: this._snowflakeAccountEditor.liveValue,
                    username: this._snowflakeAuthType === SnowflakeAuthType.Basic ? this._snowflakeUserNameEditor.liveValue : null,
                    password: this._snowflakeAuthType === SnowflakeAuthType.Basic ? this._snowflakePasswordEditor.liveValue : null,
                    privateKey: this._snowflakeAuthType === SnowflakeAuthType.PrivateKey ? this._snowflakePrivateKeyEditor.liveValue : null,
                    warehouse: this._snowflakeWarehouseEditor.liveValue,
                    database: this._snowflakeDatabaseEditor.liveValue,
                    schema: this._snowflakeSchemaEditor.liveValue,
                    role: this._snowflakeRoleEditor.liveValue,
                    stage: this._snowflakeStageEditor.liveValue,
                };

                destination = {
                    id: this._destinationId,
                    destinationName: this._nameEditor.liveValue,
                    description: this._descriptionEditor.liveValue,
                    configJson: JSON.stringify(snowflakeConfigJson),
                    parsedConfigJson: snowflakeConfigJson,
                    destinationType: this._destinationType.liveValue,
                    createdBy: null,
                };
            } else if (this._destinationType.liveValue === DestinationType.FTP) {
                const ftpConfigJson = {
                    //protocol: Object.keys(FtpProtocol)[Object.values(FtpProtocol).indexOf(this._ftpProtocolEditor.liveValue as FtpProtocol)], //Find the enum key which will match the enum name on the server side.
                    protocol: this._ftpProtocol,
                    host: this._ftpHostEditor.liveValue,
                    port: this._ftpPortEditor.liveValue,
                    username: this._ftpUsernameEditor.liveValue,
                    privateKey: this._ftpPrivateKeyEditor.liveValue,
                    password: this._ftpPasswordEditor.liveValue,
                };

                destination = {
                    id: this._destinationId,
                    destinationName: this._nameEditor.liveValue,
                    description: this._descriptionEditor.liveValue,
                    configJson: JSON.stringify(ftpConfigJson),
                    parsedConfigJson: ftpConfigJson,
                    destinationType: this._destinationType.liveValue,
                    createdBy: null,
                };
            }

            //create/edit
            const res = this._isNew
                ? await this._destinationService.api.createAsync(destination)
                : await this._destinationService.api.updateAsync(this._destinationId, destination);

            if (res.isOk) {
                history.back();
            } else {
                this._saveButton.disabled = false;
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    async typeChangeEdit(forcedType) {
        let value = this._destinationType.liveValue;
        if (typeof forcedType === "string") {
            value = forcedType;
        }
        if (value === DestinationType.S3) {
            this._showBucketConfig = true;
            this._showGoogleSheetConfig = false;
            this._showGoogleDriveConfig = false;
            this._showSnowflakeConfig = false;
            this._showFtpConfig = false;
            this._requireExternalIdEditor.liveValue = this._destination?.parsedConfigJson?.externalId != undefined
            this.requireExternalIdChanged();
        } else if (value === DestinationType.GoogleSheets) {
            if (this._googleOAuthType === GoogleOAuthType.ServiceAccount) this._showGSheetField = true;
            else this._showGSheetField = false;

            this._showBucketConfig = false;
            this._showSnowflakeConfig = false;
            this._showFtpConfig = false;
            this._showGoogleSheetConfig = true;
            this._showGoogleDriveConfig = false;

            if (this._isNew) {
                if (forcedType) this._googleOAuthType = GoogleOAuthType.SignInButton;
                else this._googleOAuthType = GoogleOAuthType.ServiceAccount;
            } else {
                if (this._destination?.parsedConfigJson?.googleOAuthType == GoogleOAuthType.SignInButton)
                    this._googleOAuthType = GoogleOAuthType.SignInButton;
                else this._googleOAuthType = GoogleOAuthType.ServiceAccount;
            }
            if (this._SheetTempId) {
                this._destinationId = this._SheetTempId;
                this._showGAuthButton = false;
                this._showGSheetField = true;
                this._showGDriveField = false;
            }

            this.GoogleOAuthTypeChange();
        } else if (value === DestinationType.GoogleDrive) {
            if (this._isNew) {
                if (forcedType) this._googleOAuthType = GoogleOAuthType.SignInButton;
                else this._googleOAuthType = GoogleOAuthType.ServiceAccount;
            } else {
                if (this._destination?.parsedConfigJson?.googleOAuthType == GoogleOAuthType.SignInButton)
                    this._googleOAuthType = GoogleOAuthType.SignInButton;
                else this._googleOAuthType = GoogleOAuthType.ServiceAccount;
            }

            this._showGDriveField = false;
            this._showBucketConfig = false;
            this._showSnowflakeConfig = false;
            this._showFtpConfig = false;
            this._showGoogleSheetConfig = false;
            this._showGoogleDriveConfig = true;
            if (this._SheetTempId) {
                this._destinationId = this._SheetTempId;
                this._showGAuthButton = false;
                this._showGSheetField = false;
                this._showGDriveField = true;
            }

            this.GoogleOAuthTypeChange();
        } else if (value === DestinationType.Snowflake) {
            this._showSnowflakeConfig = true;
            this._showFtpConfig = false;
            this._showBucketConfig = false;
            this._showGoogleSheetConfig = false;
            this._showGoogleDriveConfig = false;

            if (this._isNew) {
                if (forcedType) this._snowflakeAuthType = SnowflakeAuthType.Basic;
                else this._snowflakeAuthType = SnowflakeAuthType.PrivateKey;
            } else {
                if (this._destination?.parsedConfigJson?.snowflakeAuthType == SnowflakeAuthType.Basic)
                    this._snowflakeAuthType = SnowflakeAuthType.Basic;
                else this._snowflakeAuthType = SnowflakeAuthType.PrivateKey;
            }

            //this.onSnowflakeAuthTypeChanged();
        } else if (value === DestinationType.FTP) {
            this._showSnowflakeConfig = false;
            this._showFtpConfig = true;
            this._showBucketConfig = false;
            this._showGoogleSheetConfig = false;
            this._showGoogleDriveConfig = false;
            this._ftpProtocol = this._destination?.parsedConfigJson?.protocol ?? FtpProtocol.Ftp;
            this._ftpPort = this._destination?.parsedConfigJson?.port ?? 21;
            this._ftpPassword = this._destination?.parsedConfigJson?.password;
            this._ftpPrivateKey = this._destination?.parsedConfigJson?.privateKey;
        }
    }

    checkGoogleOauth(type) {
        return { display: type ? "block" : "none" };
    }

    exportOptionsStyle(type) {
        return { display: type ? "block" : "none" };
    }

    onFtpProtocolChanged() {
        this._ftpProtocol = this._ftpProtocolEditor.liveValue as FtpProtocol;
        if (this._ftpProtocol === FtpProtocol.Sftp || this._ftpProtocol === FtpProtocol.SftpPrivateKey) this._ftpPort = 22;
        else this._ftpPort = 21;
    }
    onFtpHostChanged() {
        this._ftpPassword = undefined;
        this._ftpPrivateKey = undefined;
    }

    private async TestS3Bucket() {
        if (this.reportValidity()) {
            const s3ConfigJson = this.prepareS3ConfigJson();
            const destination = this.prepareS3Destination(s3ConfigJson);

            //disclaimer
            //if (s3ConfigJson.bucketName !== this._destination?.parsedConfigJson?.bucketName) {
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Disclaimer",
                body: `Continuing the test will try to upload an empty .txt file to your S3, do you wish to continue?`,
                saveCaption: "Continue",
            });
            this._saveButton.disabled = false;
            if (!result.isSave) return;
            // }

            //try to upload a txt file to test if we have access
            const TestRes = await this._destinationService.api.testBucketAsync(destination);
            this._saveButton.disabled = false;
            if (!TestRes.isOk) {
                this._saveButton.disabled = false;
                return this._toasterService.showNetworkError(TestRes.err);
            } else {
                return this._toasterService.showSuccess("Test successful! File uploaded to the S3 bucket.");
            }
        }
    }

    async GetAvailableGoogleDriveFolders() {
        this._googleOAuthType = this._googleOAuthTypeEditor.liveValue as GoogleOAuthType;
        if (
            this._googleOAuthType === GoogleOAuthType.ServiceAccount &&
            (!this._gCredentialsEditor.liveValue.trim() ||
                !(this._gCredentialsEditor.liveValue.trim() === "[hidden]" || isValidJson(this._gCredentialsEditor.liveValue)))
        ) {
            return this._toasterService.showError("Please enter service account credentials.");
        }
        if (this._googleOAuthType === GoogleOAuthType.SignInButton && !this._destinationId) {
            return this._toasterService.showError("Please Sing in with Google.");
        }

        const foldersResult = await this._destinationService.api.getGoogleDriveFoldersAsync({
            serviceAccount: this._googleOAuthType === GoogleOAuthType.ServiceAccount ? this._gCredentialsEditor.liveValue : null,
            destinationId: this._destinationId,
            destinationName: this._nameEditor.liveValue,
        });
        console.log(foldersResult);

        if (foldersResult.isErr) {
            return this._toasterService.showError("Unable to retrieve avalable folders from Google Drive.");
        } else {
            this._googleDriveFolders = foldersResult.value;
        }
    }

    private async TestGoogleDrive() {
        if (
            this._googleOAuthType === GoogleOAuthType.ServiceAccount &&
            (!this._gCredentialsEditor.liveValue.trim() ||
                !(this._gCredentialsEditor.liveValue.trim() === "[hidden]" || isValidJson(this._gCredentialsEditor.liveValue)))
        ) {
            return this._toasterService.showError("Please enter service account credentials.");
        }

        if (this._googleOAuthType === GoogleOAuthType.SignInButton && !this._destinationId) {
            return this._toasterService.showError("Please Sing in with Google.");
        }

        if (!this._gDriveFolderEditor.liveValue.trim()) {
            return this._toasterService.showError("Please enter Google Drive Folder.");
        }

        const modalResult = await this._modalService.openConfirmDialogAsync({
            title: "Disclaimer",
            body: `Continuing the test will try to upload an empty .txt file to your Google Drive, do you wish to continue?`,
            saveCaption: "Continue",
        });
        if (!modalResult.isSave) return;

        const testResult = await this._destinationService.api.testGoogleDriveAsync({
            serviceAccount: this._googleOAuthType === GoogleOAuthType.ServiceAccount ? this._gCredentialsEditor.liveValue : null,
            destinationId: this._destinationId,
            destinationName: this._nameEditor.liveValue,
        });

        console.log(testResult);

        if (testResult.isErr) {
            return this._toasterService.showError(testResult.err.message);
        } else {
            return this._toasterService.showSuccess("Test successful! File uploaded to Google Drive.");
        }
    }

    private buildGoogleSheetDestination(): any {
        const SheetConfigJson = {
            spreadsheetLink: this._sheetsEditor.liveValue,
            serviceAccount: this._googleOAuthType === GoogleOAuthType.ServiceAccount ? this._gCredentialsEditor.liveValue : null,
        };

        const destination = {
            id: this._destinationId,
            destinationName: this._nameEditor.liveValue,
            description: this._descriptionEditor.liveValue,
            configJson: JSON.stringify(SheetConfigJson),
            parsedConfigJson: SheetConfigJson,
            destinationType: this._destinationType.liveValue,
            createdBy: null,
        };

        return destination;
    }

    private buildGoogleDriveDestination(): any {
        const GDriveConfigJson = {
            //googleOAuthType: this._googleOAuthType,
            serviceAccount: this._googleOAuthType === GoogleOAuthType.ServiceAccount ? this._gCredentialsEditor.liveValue : null,
            gDriveFolder: this._gDriveFolderEditor.liveValue,
        };

        const destination = {
            id: this._destinationId,
            destinationName: this._nameEditor.liveValue,
            description: this._descriptionEditor.liveValue,
            configJson: JSON.stringify(GDriveConfigJson),
            parsedConfigJson: GDriveConfigJson,
            destinationType: this._destinationType.liveValue,
            createdBy: null,
        };

        return destination;
    }

    prepareS3ConfigJson(): any {
        let s3ConfigJson;

        if (this._requireExternalIdEditor.liveValue === true) {
            s3ConfigJson = {
                policy: this._policyEditor.liveValue,
                bucketName: this._bucketNameEditor.liveValue,
                folder: this._bucketFolderEditor.liveValue,
                externalId: this._externalIdEditor.liveValue,
                roleArn: this._roleArnEditor.liveValue,
            };
        } else {
            s3ConfigJson = {
                policy: this._policyEditor.liveValue,
                bucketName: this._bucketNameEditor.liveValue,
                folder: this._bucketFolderEditor.liveValue,
            };
        }

        return s3ConfigJson;
    }

    prepareS3Destination(s3ConfigJson: any): any {
        const destination = {
            id: this._destinationId,
            destinationName: this._nameEditor.liveValue,
            description: this._descriptionEditor.liveValue,
            configJson: JSON.stringify(s3ConfigJson),
            parsedConfigJson: s3ConfigJson,
            destinationType: this._destinationType.liveValue,
            createdBy: null,
        };

        return destination;
    }

    private async onGetGoogleDriveFolders() {
        console.log("onGetGoogleDriveFolders");
        return this._googleDriveFolders;
    }

    private async onGoogleDriveFolderChanged() {}

    private async onGoogleDriveFolderInput() {}

    private getGoogleOAuthType() {
        if (this._isNew) return this._googleOAuthType;
        else {
            if (this._destination?.parsedConfigJson?.googleOAuthType == GoogleOAuthType.ServiceAccount)
                return GoogleOAuthType.ServiceAccount;
            else return GoogleOAuthType.SignInButton;
        }
    }

    GoogleOAuthTypeChange() {
        if (this._googleOAuthType === GoogleOAuthType.ServiceAccount) {
            this._showGAuthButton = false;
        } else if (!this._SheetTempId && !this._destinationId) {
            this._showGAuthButton = true;
        }
    }

    onGoogleOAuthTypeChanged() {
        this._googleOAuthType = this._googleOAuthTypeEditor
            ? (this._googleOAuthTypeEditor.liveValue as GoogleOAuthType)
            : GoogleOAuthType.ServiceAccount;
        this.GoogleOAuthTypeChange();
    }

    onServiceAccountFocus() {
        if (this._gCredentialsEditor.liveValue.trim() === "[hidden]") {
            this.gServiceAccountValue = "[hidden]";
            this._gCredentialsEditor.value = "";
        }
    }
    onServiceAccountBlur() {
        if (this._gCredentialsEditor.liveValue.trim() === "" && this.gServiceAccountValue === "[hidden]") {
            this._gCredentialsEditor.value = "[hidden]";
        }
    }
    generateExternalId() {
        if (this._externalId) return this._externalId;

        this._externalId = "seq_" + nanoid();
        console.log(this._externalId);
    }

    private getSnowflakeAuthType() {
        if (this._isNew) return this._snowflakeAuthType;
        else {
            if (this._destination?.parsedConfigJson?.snowflakeAuthType == SnowflakeAuthType.Basic) return SnowflakeAuthType.Basic;
            else return SnowflakeAuthType.PrivateKey;
        }
    }

    onSnowflakeAuthTypeChanged() {
        this._snowflakeAuthType = this._snowflakeAuthTypeEditor
            ? (this._snowflakeAuthTypeEditor.liveValue as SnowflakeAuthType)
            : SnowflakeAuthType.Basic;
        alert(this._snowflakeAuthType);
    }

    onSnowflakePrivateKeyFocus() {
        if (this._snowflakePrivateKeyEditor.liveValue.trim() === "[hidden]") {
            this.snowflakePrivateKeyValue = "[hidden]";
            this._snowflakePrivateKeyEditor.value = "";
        }
    }
    onSnowflakePrivateKeyBlur() {
        if (this._snowflakePrivateKeyEditor.liveValue.trim() === "" && this.snowflakePrivateKeyValue === "[hidden]") {
            this._snowflakePrivateKeyEditor.value = "[hidden]";
        }
    }
    render() {
        //console.log(this.getGoogleOAuthType());
        //console.log(this._destination?.parsedConfigJson);
        return html`
            <form id="editorForm" class="editor">
                ${this._isLoading
                    ? html`<div class="loading-container">
                          <div style="margin: auto"
                              ><fa-icon style="font-size:0.9em" fa-class="far fa-spinner fa-spin"></fa-icon>&nbsp;&nbsp;Loading...</span
                          >
                      </div>`
                    : html`
                          <div class="h3">${this._isNew ? "Add New" : "Edit"} Destination</div>
                          <div class="scroll-container">
                              <se-input-editor
                                  id="destinationName"
                                  name="destinationName"
                                  type="text"
                                  label="Destination Name"
                                  labelPosition="top"
                                  input-type="text"
                                  required
                                  width="100%"
                                  .value=${this._destination.destinationName}
                              ></se-input-editor>
                              <se-textarea-editor
                                  id="description"
                                  name="description"
                                  type="text"
                                  label="Description"
                                  labelPosition="top"
                                  input-type="text"
                                  .value=${this._destination.description ?? ""}
                                  input-resize="vertical"
                              ></se-textarea-editor>
                              <se-select-editor
                                  id="destinationType"
                                  .value="${"S3 Bucket"}"
                                  width="100%"
                                  name="destinationType"
                                  label="Destination Type"
                                  labelPosition="top"
                                  .disabled=${this._isEdit}
                                  @valueChanged=${this.typeChangeEdit}
                                  .textOptions=${["S3 Bucket", "Google Drive", "Google Sheets", "Snowflake", "FTP"]}
                              ></se-select-editor>

                              ${this._showBucketConfig
                                  ? html`
                            <span
                             ><span style="font: var(--font-smaller);"
                                          >If you need help, please view <a href="https://sequentum.atlassian.net/l/cp/7Cw1trA8" target="_blank">the setup guide</a>.</span
                                      >
                                  </span>
                              </span>
                              <se-input-editor
                                  @editorChanged=${this.nameChanged}
                                  id="bucketName"
                                  name="bucketName"
                                  type="text"
                                  label="Bucket Name"
                                  labelPosition="top"
                                  width="100%"
                                  input-type="text"
                                  required
                                  .value=${this._destination?.parsedConfigJson?.bucketName}
                              ></se-input-editor>

                                                            <div style="display: flex;flex-direction: column;gap: 5px;">
                                  <se-input-editor
                                      id="bucketFolder"
                                      name="bucketFolder"
                                      type="text"
                                      label="Folder"
                                      labelPosition="top"
                                      width="100%"
                                      input-type="text"
                                      required
                                      .value=${this._destination?.parsedConfigJson?.folder}
                                  ></se-input-editor>
                                  <span
                                      ><span style="font: var(--font-smaller);"
                                          >The following placeholders are supported {ConfigId}, {ConfigName}, any valid timestamp, e.g.
                                          {yyyyMMddhhmm}</span
                                      >
                                  </span>
                              </div>

                                <span style="display: flex;flex-direction: column;gap: 5px;">
                                  <se-textarea-editor
                                      id="policy"
                                      name="policy"
                                      type="text"
                                      label="Policy"
                                      labelPosition="top"
                                      input-type="text"
                                      required
                                      rows=${this._policyRows}
                                      spellcheck="false"
                                      readonly
                                      .value=${this._destination?.parsedConfigJson?.policy ?? ""}
                                      input-resize="vertical"
                                  ></se-textarea-editor>
                                  ${!this._requireExternalId
                        ? html`
                                  <span
                                      ><span style="font: var(--font-smaller);"
                                          >Copy the policy to the
                                          <a href="https://sequentum.atlassian.net/l/cp/7Cw1trA8" target="_blank">permissions tab</a> of
                                          your S3 bucket</span
                                      >
                                  </span>
                              </span>`
                        : html`<span
                                      ><span style="font: var(--font-smaller);"
                                          >Copy the policy to the
                                          <a href="https://sequentum.atlassian.net/l/cp/7Cw1trA8" target="_blank">Permissions policies tab</a> of
                                          your external IAM role</span
                                      >
                                  </span>
                              </span>`
                                  }




                               <se-checkbox-editor
                               id="requireExternalId"
                               name="requireExternalId"
                               label="Require External ID (Best practice)"
                               labelPosition="right"
                               @valueChanged=${this.requireExternalIdChanged}
                               .value=${this._isNew ? this._isNew : this._destination?.parsedConfigJson?.externalId ? true : false}>
                               </se-checkbox-editor>
                               ${
                                   this._requireExternalId
                                       ? html` <se-input-editor
                                             @editorChanged=${this.externalIdChange}
                                             id="externalId"
                                             name="externalId"
                                             type="text"
                                             label="External ID"
                                             labelPosition="top"
                                             width="100%"
                                             input-type="text"
                                             required
                                             .value=${this._isNew ? this._externalId : this._destination?.parsedConfigJson?.externalId}
                                         ></se-input-editor>
                                             <se-input-editor
                                                 @editorChanged=${this.nameChanged}
                                                 id="roleArn"
                                                 name="roleArn"
                                                 type="text"
                                                 label="Role ARN"
                                                 labelPosition="top"
                                                 width="100%"
                                                 input-type="text"
                                                 required
                                                 .value=${this._destination?.parsedConfigJson?.roleArn}
                                             ></se-input-editor>
                                             <se-textarea-editor
                                                 id="customTrustPolicy"
                                                 name="customTrustPolicy"
                                                 type="text"
                                                 label="Role Custom Trust Policy"
                                                 labelPosition="top"
                                                 input-type="text"
                                                 required
                                                 rows="10"
                                                 spellcheck="false"
                                                 readonly
                                                 .value=${this.getCustomTrustPolicy()}
                                                 input-resize="vertical"
                                             ></se-textarea-editor>
                                             <span><span style="font: var(--font-smaller);"
                                                          >Copy the policy to the
                                                          <a href="https://sequentum.atlassian.net/l/cp/7Cw1trA8" target="_blank">Trust relationships tab</a> of
                                                          your external IAM role</span
                                                      >
                                                  </span>
                                              </span>
                                             `
                                       : html``
                               }
                                  <se-secondary-button
                                      @click="${this.TestS3Bucket}"
                                      .customStyle=${{ marginLeft: 0, marginTop: "5px" }}
                                      text="Test Connection"
                                  ></se-secondary-button>

                          `
                                  : html``}
                              ${this._showGoogleSheetConfig
                                  ? html`
                                        <div style="display:flex;flex-direction:column;gap:5px">
                                            <se-select-editor
                                                id="googleOAuthType"
                                                type="text"
                                                label="Authorization Type"
                                                labelPosition="top"
                                                @valueChanged=${this.onGoogleOAuthTypeChanged}
                                                .value="${this.getGoogleOAuthType()}"
                                                .options=${this._googleOAuthTypeOptions}
                                            ></se-select-editor>

                                            ${this._SheetTempId && this._destinationId
                                                ? html``
                                                : html`
                                                      ${this._showGAuthButton
                                                          ? html` <div>
                                                                <se-google-oauth-button .type=${"spreadsheets"}></se-google-oauth-button>
                                                            </div>`
                                                          : html`
                                                                <div style="marginTop:5px">
                                                                    <se-textarea-editor
                                                                        id="gCredentials"
                                                                        name="gCredentials"
                                                                        type="text"
                                                                        label="Service account credentials (JSON format)"
                                                                        labelPosition="top"
                                                                        input-type="text"
                                                                        required
                                                                        rows="17"
                                                                        spellcheck="false"
                                                                        .value=${this._destination?.parsedConfigJson
                                                                            ? this._destination?.parsedConfigJson?.googleOAuthType ==
                                                                              GoogleOAuthType.ServiceAccount
                                                                                ? "[hidden]"
                                                                                : ""
                                                                            : ""}
                                                                        @focus="${this.onServiceAccountFocus}"
                                                                        @blur="${this.onServiceAccountBlur}"
                                                                        input-resize="vertical"
                                                                    ></se-textarea-editor>
                                                                </div>
                                                            `}
                                                  `}
                                            ${this._showGSheetField
                                                ? html`
                                                      <se-input-editor
                                                          id="googleSheetsList"
                                                          name="Google Sheets List"
                                                          type="text"
                                                          label="Google Sheets Link"
                                                          labelPosition="top"
                                                          width="100%"
                                                          input-type="text"
                                                          required
                                                          .value="${this._destination?.parsedConfigJson?.spreadsheetLink ||
                                                          this._Sheets?.[0]?.id}"
                                                      ></se-input-editor>
                                                  `
                                                : html``}
                                        </div>
                                    `
                                  : html``}
                              ${this._showGoogleDriveConfig
                                  ? html`
                                        <se-select-editor
                                            id="googleOAuthType"
                                            type="text"
                                            label="Authorization Type"
                                            labelPosition="top"
                                            @valueChanged=${this.onGoogleOAuthTypeChanged}
                                            .value="${this.getGoogleOAuthType()}"
                                            .options=${this._googleOAuthTypeOptions}
                                        ></se-select-editor>
                                        ${this._SheetTempId && this._destinationId
                                            ? html``
                                            : html`
                                                  ${this._showGAuthButton
                                                      ? html` <div style="marginTop:5px">
                                                            <se-google-oauth-button .type=${"drive"}></se-google-oauth-button>
                                                        </div>`
                                                      : html`
                                                            <se-textarea-editor
                                                                id="gCredentials"
                                                                name="gCredentials"
                                                                type="text"
                                                                label="Service account credentials (JSON format)"
                                                                labelPosition="top"
                                                                input-type="text"
                                                                required
                                                                rows="17"
                                                                spellcheck="false"
                                                                .value=${this._destination?.parsedConfigJson
                                                                    ? this._destination?.parsedConfigJson?.googleOAuthType ==
                                                                      GoogleOAuthType.ServiceAccount
                                                                        ? "[hidden]"
                                                                        : ""
                                                                    : ""}
                                                                @focus="${this.onServiceAccountFocus}"
                                                                @blur="${this.onServiceAccountBlur}"
                                                                input-resize="vertical"
                                                            ></se-textarea-editor>

                                                            <se-secondary-button
                                                                @click="${this.GetAvailableGoogleDriveFolders}"
                                                                .customStyle=${{ marginLeft: 0, marginTop: "5px" }}
                                                                text="Get Available Folders"
                                                            ></se-secondary-button>
                                                        `}
                                              `}
                                        ${this._showGDriveField
                                            ? html`
                                                  <div style="display: flex;flex-direction: column;gap: 5px;">
                                                      <se-typeahead-editor
                                                          show-all
                                                          id="gDriveFolder"
                                                          type="text"
                                                          label="Google Drive Folder"
                                                          labelPosition="top"
                                                          maxLength="500"
                                                          .getTextOptions="${this.onGetGoogleDriveFolders.bind(this)}"
                                                          .value="${this._destination?.parsedConfigJson?.gDriveFolder ?? ""}"
                                                          @valueChanged=${this.onGoogleDriveFolderChanged}
                                                          @editorChanged=${this.onGoogleDriveFolderInput}
                                                      ></se-typeahead-editor>
                                                  </div>
                                                  <span
                                                      ><span style="font: var(--font-smaller);"
                                                          >The following placeholders are supported {ConfigId}, {ConfigName}, any valid
                                                          timestamp, e.g. {yyyyMMddhhmm}</span
                                                      >
                                                  </span>
                                              `
                                            : html``}
                                        <div>
                                            <se-secondary-button
                                                @click="${this.TestGoogleDrive}"
                                                .customStyle=${{ marginLeft: 0, marginTop: "5px" }}
                                                text="Test Connection"
                                            ></se-secondary-button>
                                        </div>
                                    `
                                  : html``}
                              ${this._showSnowflakeConfig
                                  ? html`
                                        <div style="display:flex;flex-direction:column;gap:5px">
                                            <se-input-editor
                                                id="snowflakeAccount"
                                                type="text"
                                                label="Account"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                required
                                                .value="${this._destination?.parsedConfigJson?.account}"
                                            ></se-input-editor>
                                            <se-select-editor
                                                id="snowflakeAuthType"
                                                type="text"
                                                label="Authorization Type"
                                                labelPosition="top"
                                                @valueChanged=${this.onSnowflakeAuthTypeChanged}
                                                .value="${this.getSnowflakeAuthType()}"
                                                .options=${this._snowflakeAuthTypeOptions}
                                            ></se-select-editor>

                                            ${this._snowflakeAuthType === SnowflakeAuthType.Basic
                                                ? html`
                                                      <se-input-editor
                                                          id="snowflakeUsername"
                                                          type="text"
                                                          label="User Name"
                                                          labelPosition="top"
                                                          width="100%"
                                                          input-type="text"
                                                          required
                                                          .value="${this._destination?.parsedConfigJson?.username}"
                                                      ></se-input-editor>
                                                      <se-input-editor
                                                          id="snowflakePassword"
                                                          type="text"
                                                          label="Password"
                                                          labelPosition="top"
                                                          width="100%"
                                                          input-type="password"
                                                          required
                                                          .value="${this._destination?.parsedConfigJson?.password}"
                                                      ></se-input-editor>
                                                  `
                                                : html`
                                                      <se-textarea-editor
                                                          id="snowflakePrivateKey"
                                                          name="snowflakePrivateKey"
                                                          type="text"
                                                          label="Private key"
                                                          labelPosition="top"
                                                          input-type="text"
                                                          required
                                                          rows="17"
                                                          spellcheck="false"
                                                          .value=${this._destination?.parsedConfigJson
                                                              ? this._destination?.parsedConfigJson?.snowflakeAuthType ===
                                                                SnowflakeAuthType.PrivateKey
                                                                  ? "[hidden]"
                                                                  : ""
                                                              : ""}
                                                          @focus="${this.onSnowflakePrivateKeyFocus}"
                                                          @blur="${this.onSnowflakePrivateKeyBlur}"
                                                          input-resize="vertical"
                                                      ></se-textarea-editor>
                                                  `}
                                            <se-input-editor
                                                id="snowflakeRole"
                                                type="text"
                                                label="Role (optional)"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                .value="${this._destination?.parsedConfigJson?.role}"
                                            ></se-input-editor>
                                            <se-input-editor
                                                id="snowflakeDatabase"
                                                type="text"
                                                label="Default database (optional)"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                .value="${this._destination?.parsedConfigJson?.database}"
                                            ></se-input-editor>
                                            <se-input-editor
                                                id="snowflakeSchema"
                                                type="text"
                                                label="Default schema (optional)"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                .value="${this._destination?.parsedConfigJson?.schema}"
                                            ></se-input-editor>
                                            <se-input-editor
                                                id="snowflakeStage"
                                                type="text"
                                                label="Default named stage (optional)"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                .value="${this._destination?.parsedConfigJson?.stage}"
                                            ></se-input-editor>
                                            <se-input-editor
                                                id="snowflakeWarehouse"
                                                type="text"
                                                label="Default warehouse (optional)"
                                                labelPosition="top"
                                                width="100%"
                                                input-type="text"
                                                .value="${this._destination?.parsedConfigJson?.warehouse}"
                                            ></se-input-editor>
                                        </div>
                                    `
                                  : html``}
                              ${this._showFtpConfig
                                  ? html`
                    <div style="display:flex;flex-direction:column;gap:5px">
                        <se-select-editor
                            id="ftpProtocol"
                            type="text"
                            label="Protocol"
                            labelPosition="top"
                            @valueChanged=${this.onFtpProtocolChanged}
                            .value="${this._ftpProtocol}"
                            .options=${this._protocolOptions}
                        ></se-select-editor>
                        <div style="display:flex; gap: 5px">
                            <se-input-editor                                
                                id="ftpHost"
                                type="text"
                                label="Host"
                                labelPosition="top"
                                input-type="text"
                                required
                                .value="${this._destination?.parsedConfigJson?.host ?? ""}"
                                @valueChanged=${this.onFtpHostChanged}
                            ></se-input-editor>
                            <se-number-editor
                                id="ftpPort"
                                label="Port"
                                labelPosition="top"
                                required
                                input-width="50px"
                                min="0"
                                max="5000"
                                .value="${this._ftpPort}"
                            ></se-number-editor>
                        </div>
                        <se-input-editor
                            id="ftpUsername"
                            label="Username"
                            labelPosition="top"
                            input-type="text"
                            required
                            .value="${this._destination?.parsedConfigJson?.username ?? ""}"
                        ></se-input-editor>
                        <se-textarea-editor
                            ?hidden=${this._ftpProtocol !== FtpProtocol.SftpPrivateKey}
                            id="ftpPrivateKey"
                            label="Private key"
                            labelPosition="top"
                            .value="${this._ftpPrivateKey ?? ""}"
                            input-resize="vertical"
                        ></se-textarea-editor>
                        <se-input-editor
                            id="ftpPassword"
                            label="Password"
                            labelPosition="top"
                            input-type="password"
                            required
                            .value="${this._ftpPassword ?? ""}"
                            input-resize="vertical"
                        ></se-input-editor>
                    </div>
                </div>
                    `
                                  : html``}
                          </div>
                          <div class="savePanel">
                              <se-primary-button
                                  id="saveBtn"
                                  .action="${() => this.saveAsync()}"
                                  action-delay="500"
                                  text="Save"
                              ></se-primary-button>
                              <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                          </div>
                      `}
            </form>
        `;
    }
    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .loading-container {
            width: 500px;
            height: 100px;
            background-color: white;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            align-items: center;
        }
        .scroll-container {
            width: 500px;
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
