import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { AuditLogService } from "../../services/audit-log.service";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationService } from "../../services/organization.service";
import { UserApi } from "../../services/user-api";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { SePaginationElement } from "../components/pagination.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import "./organization-editor.element";

@customElement("se-organizations")
export class SeOrganizationsElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _auditService: AuditLogService;
    private _organizationService: OrganizationService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    private _pageIndex = 1;
    private _recordsPerPage = 20;
    private _totalRecordCount: number;
    private _name = "";
    private _sortColumn = "Name";
    private _sortOrder = -1;

    private _savedPageIndex = 1;
    private _agentName = "";
    private _isProgrammaticallySet = true;
    private lastSearchedValue = "";
    private searchQueue = [];
    private processingSearch = false;
    @query("#search") private searchInput: InputEditorElement;

    @query("se-pagination") private _pagination: SePaginationElement;

    @state() private _data: OrganizationViewModel[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    private _userApi: UserApi;
    constructor() {
        super();
        this._auditService = container.resolve(AuditLogService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._organizationService = container.resolve(OrganizationService);
        this._userApi = container.resolve(UserApi);
    }

    private menu(row: OrganizationViewModel, col: DataGridColumn): MenuItem[] {
        const menu = [];
        menu.push({ text: "Edit", action: this.editOrg.bind(this, row, col) });
        menu.push({ text: "-" });
        if (!row.isApproved) {
            menu.push({ text: "Approve Org Admins", action: this.approveOrgAdminsAsync.bind(this, row, col) });
            menu.push({ text: "-" });
        }
        if (this._authService.user.organizationId !== row.id) {
            menu.push({ text: "Switch to Org", action: this.switchOrganizationAsync.bind(this, row) });
            menu.push({ text: "-" });
        }
        menu.push({ text: "Delete Audit Log", action: () => this.deleteAuditLogAsync(row) });
        menu.push({ text: "-" });
        menu.push({ text: "Delete", action: this.deleteOrganizationAsync.bind(this, row, col) });
        return menu;
    }

    private async switchOrganizationAsync(orgRow) {
        if (this._authService.user.organizationId !== orgRow.id) {
            const result = await this._userApi.changeOrganizationAsync(orgRow.id);
            if (result.isOk) {
                this._authService.changeOrganization(orgRow.id, orgRow.name);
                await this._userState.refreshUserSpacesAsync();
                this._userState.selectedSpaceId = 0;
                this._userState.selectedLabelId = 0;
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
        if (location.params.approvalString) {
            const approvalString = location.params.approvalString.valueOf() as string;
            const result = await this._organizationService.api.approveOrgAsync(approvalString);
            if (result.isOk) {
                this._toasterService.showSuccess("Organization has been approved.");
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private editOrg(row: OrganizationViewModel) {
        Router.go(`/edit/organization/${row.id}`);
    }

    private async deleteOrganizationAsync(row: OrganizationViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Organization",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            if (row.id === this._authService.user.organizationId) {
                this._toasterService.showError("You cannot delete your own organization.");
            } else {
                const result = await this._organizationService.api.deleteAsync(row.id);
                if (result.isOk) {
                    const index = this._data.indexOf(row);
                    this._data.splice(index, 1);
                    if (row.selected) this.gridSelectionChanged();
                    this._dataGrid.requestUpdate();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }

    private async deleteAuditLogAsync(row: OrganizationViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete All Audit Logs for Organization",
            body: `Are you sure you want to delete all audit logs for ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._auditService.api.deleteOrganizationAsync(row.id);
            if (result.isOk) {
                this._toasterService.showSuccess("Audit logs deleted successfully");
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async approveOrgAdminsAsync(row: OrganizationViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Approve Organization Admins",
            body: `Are you sure you want to approve ${row.name}'s admins?`,
            saveCaption: "Approve",
        });
        if (result.isSave) {
            const result = await this._organizationService.api.approveOrgAdminsAsync(row.id);
            if (result.isOk) {
                this._dataGrid.requestUpdate();
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    firstUpdated() {
        this.lastSearchedValue = this.getCookie("lastSearchedValue") || "";

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        const pulledPage = Number(searchParams.get("gridPage") || this._pageIndex);
        if (this.lastSearchedValue !== "") {
            this._agentName = this.lastSearchedValue;
            this._pagination.changePage(pulledPage || 1);
        }

        new CustomEvent("customPageChange", {
            detail: { pageIndex: pulledPage },
        });
    }
    private setCookie(name, value, minutes) {
        const now = new Date();
        now.setTime(now.getTime() + minutes * 60 * 1000);
        const expires = "expires=" + now.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
    private getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private async filterByOrganizationName() {
        const newValue = this.searchInput.liveValue;
        const trimmedNewValue = newValue.trim();
        if (
            (this.lastSearchedValue === "" || (this.lastSearchedValue === "null" && this._isProgrammaticallySet)) &&
            trimmedNewValue !== ""
        ) {
            this._savedPageIndex = this._pageIndex;
        }
        this._isProgrammaticallySet = false;

        if (this._agentName.trim() === "" && trimmedNewValue === "") {
            this.searchQueue = [];
            return;
        }
        this._agentName = newValue;
        if (trimmedNewValue === "") {
            this._pageIndex = this._savedPageIndex || 1;
            this._dataGrid.pageIndex = this._savedPageIndex || 1;
            this._pagination.changePage(this._savedPageIndex || 1);
        } else {
            this._pageIndex = 1;
            this._dataGrid.pageIndex = this._pageIndex;
        }

        if (trimmedNewValue === "") {
            this._agentName = "";
            this._isProgrammaticallySet = true;
        }
        if (this.lastSearchedValue === this._agentName) {
            return;
        }
        if (this.processingSearch === false) {
            this.searchQueue.push(this._agentName);
            this.processingSearch = true;
            await this.loadData(this.searchQueue[0]);
            this.processingSearch = false;
            this.searchQueue.shift();
        } else {
            if (this.searchQueue.length !== 1) {
                this.searchQueue.pop();
            }
            const repeating = setInterval(() => {
                if (this.processingSearch === false) {
                    clearInterval(repeating);
                    this.filterByOrganizationName();
                }
            }, 100);
        }
    }

    private async loadData(nameValue: string = this.lastSearchedValue) {
        this._columns = [
            { field: "name", title: "Name", actionLink: this.editOrg },
            { field: "adminEmail", title: "Admin" },
            { field: "userCount", title: "User Count", align: "center" },
            {
                field: "lastActivity",
                title: "Last Activity",
                align: "center",
                template: (row) => {
                    const date = !row.lastActivity
                        ? "-"
                        : `${new Date(row.lastActivity).toLocaleDateString()} ${new Date(row.lastActivity).toLocaleTimeString()}`;
                    return html`${date}`;
                },
            },
            { field: "balance", title: "Balance", align: "center" },
            { field: "isMfaRequired", title: "MFA Enforced", align: "center", checkbox: true },
            { field: "isProxyRequired", title: "Proxy Required", align: "center", checkbox: true },
            { field: "isAuditLog", title: "Audit Enabled", align: "center", checkbox: true },
            { field: "isBillable", title: "Billable", align: "center", checkbox: true },
            {
                field: "isApproved",
                title: "Status",
                template: (row) =>
                    html`${choose(
                        row.isApproved,
                        [
                            [undefined || null, () => html`N/A`],
                            [false, () => html`<span style="color:var(--color-status-red)">Under Review</span>`],
                            [true, () => html`<span style="color:var(--color-status-green)">Active</span>`],
                        ],
                        () => html`${row.isApproved}`
                    )}`,
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        this.lastSearchedValue = nameValue;
        this.setCookie("lastSearchedValue", this.lastSearchedValue, 15);

        const organizationRequest = {
            name: this.lastSearchedValue,
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder,
        };

        const result = await this._organizationService.api.getAllDetailsAsync(organizationRequest);
        if (result.isOk) {
            this._data = result.value.organizations;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Organizations",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} organizations`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const orgIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            if (orgIds.some((p) => p === this._authService.user.organizationId)) {
                this._toasterService.showError("You cannot delete your own organization.");
            } else {
                const result = await this._organizationService.api.deleteAllAsync(orgIds);
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.loadData();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }

    newOrganization() {
        Router.go(`/edit/organization`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                        <se-input-editor
                            id="search"
                            name="filter"
                            type="text"
                            label="Filter&nbsp;by&nbsp;Organization&nbsp;Name"
                            labelPosition="left"
                            gap="5px"
                            input-type="text"
                            size="30"
                            @editorChanged=${this.filterByOrganizationName}
                        ></se-input-editor>
                    </div>
                    <se-primary-button text="New Organization" @click="${this.newOrganization}"></se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    placeholder="No organizations available."
                ></se-data-grid>
                <div style="overflow:hidden;display:flex;justify-content: center; margin-top: 5px;">
                    <se-pagination
                        .recordsPerPage=${this._recordsPerPage}
                        .recordCount=${this._totalRecordCount}
                        @pagechanged=${this.onPageChanged}
                    ></se-pagination>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
